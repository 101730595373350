import styled from "styled-components";

export const OurStoryTop = styled.div`
  width: calc(calc(var(--width) - var(--outerMargin)) - var(--outerMargin));
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  --accentColor: ${(props) => props.accentColor || "var(--black)"};
  & h5 {
    margin: 15px 0 30px 0;
    color: var(--white);
  }
  & h2 {
    color: var(--accentColor);
  }
  & .player-wrapper {
    border-top: 3px solid var(--accentColor);
    position: relative;
    width: calc(calc(var(--width) - var(--outerMargin)) - var(--outerMargin));
    margin: 33px auto 22px auto;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    & .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  @media (max-width: 1439px) {
    padding-left: 25px;
    padding-right: 25px;
    & h2 {
      font-size: 36px;
      line-height: 48px;
    }
  }
  @media (max-width: 767px) {
    --outerMargin: 0;
    & .player-wrapper {
      width: 100%;
      height: 100%;
      & img {
        width: 100px;
        height: 100px;
      }
    }
    & > h2 {
      font-size: 24px;
      line-height: 30px;
    }
  }
`;

export const OurStoryBottom = styled.div`
  width: var(--width);
  margin: 0 auto;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &:before {
    position: absolute;
    left: -100vw;
    right: -100vw;
    top: 0;
    bottom: 0;
    z-index: 0;
    content: "";
    background-color: var(--white);
  }
  & > div {
    z-index: 1;
  }
  & button {
    margin-bottom: 50px;
    &:hover {
      color: var(--black);
    }
  }
  & > a {
    z-index: 1;
  }
  & .todayontheavenue {
    margin-left: 0;
    padding-bottom: 0;
  }
  & section {
    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 1439px) {
    & section > div {
      padding-left: 10px;
    }
  }
`;
